<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        A solution is made from
        <number-value :value="mass" unit="\text{g}" />
        of
        <chemical-latex content="AlCl3(s)" />
        dissolved in
        <stemble-latex content="$250.0\,\text{g}$" />
        of water. Answer each of the questions below regarding this solution.
      </p>

      <p class="mb-2">a) What is the molality of the solution?</p>

      <calculation-input
        v-model="inputs.inputA"
        prepend-text="$\text{Molality: }$"
        append-text="$\text{m}$"
        :disabled="!allowEditing"
        class="mb-5"
      />

      <p class="mb-2">
        b) The boiling point constant of water is
        <stemble-latex content="$0.512\,^\circ\text{C}/\text{m.}$" />
        What is the boiling point of the solution?
      </p>

      <calculation-input
        v-model="inputs.inputB"
        prepend-text="$\text{Boiling point: }$"
        append-text="$^\circ\text{C}$"
        :disabled="!allowEditing"
        class="mb-5"
      />

      <p class="mb-2">
        c) The freezing point constant of water is
        <stemble-latex content="$1.86\,^\circ\text{C}/\text{m.}$" />
        What is the freezing point of the solution?
      </p>

      <calculation-input
        v-model="inputs.inputC"
        prepend-text="$\text{Freezing point: }$"
        append-text="$^\circ\text{C}$"
        :disabled="!allowEditing"
        class="mb-0"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1220S4_Q3',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputA: null,
        inputB: null,
        inputC: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
  },
};
</script>
